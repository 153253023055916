import { copybox } from './copybox.js';

// import { check_form, load_cities } from './check_form.js';
import { check_form, request_forms } from './check_form.js';

import { donation_form_switch } from './donation_form_switch.js';

import { faq } from './faq.js';
import { filterThousands } from './filter-thousands.js';

import { form_helper } from './form_helper.js';
import { form_user_create_project } from './form_user_create_project.js';

import { howto_click } from './howto_click.js';

import { image_widget } from './create_image_widget.js';
import { input_file } from './input_file.js';

import { lang_switch } from './lang_switch.js';

import { sandwich } from './sandwich.js';
import { slider_donors } from './slider_donors.js';
import { slider_gallery } from './slider_gallery.js';
import { slider_gallery_report } from './slider_gallery_report.js';
import { slider_howto } from './slider_howto.js';
import { slider_insta } from './slider_insta.js';
import { slider_news } from './slider_news.js';
import { slider_projects } from './slider_projects.js';
// import { slider_reviews } from './slider_reviews.js';
import { slider_top_projects } from './slider_top_projects.js';

import { two_cols_nav } from './two_cols_nav.js';
import { tab_switch } from './tab_switch.js';

import { project_filter } from './project_filter.js';

import { copy_link } from './copy_link.js';
import { base_sliders } from './base_sliders.js';


function init() {
    copybox();
    request_forms();
    check_form();
    // load_cities();

    donation_form_switch();

    faq();
    filterThousands();
    form_helper();
    form_user_create_project();

    howto_click();

    image_widget();
    input_file();

    lang_switch();

    sandwich();
    slider_gallery();
    slider_gallery_report();
    slider_donors();
    tab_switch();
    slider_howto();

    // maybe remove
    slider_insta();
    slider_news();
    slider_projects();
    // slider_reviews();
    slider_top_projects();

    two_cols_nav();
    project_filter();

    copy_link();
    base_sliders();
}

export default init;
