import debounce from 'lodash.debounce';
import Swiper from 'swiper';

let node = undefined;
let slider = undefined;

const window_switcher = () => {
    if (document.documentElement.clientWidth < 768) {

        if (!slider) {
            slider = new Swiper(node, {
                slidesPerView: 'auto',
                spaceBetween: 24,
            });
        }
        return;
    } else if (slider) {
        slider.destroy(true, true);
        slider = null;
    }
};

const slider_insta = () => {
    node = document.querySelector('.swiper-insta');
    if (!node) {
        return;
    }

    window_switcher();
    const resize_listener = debounce(window_switcher, 50);
    window.addEventListener('resize', resize_listener);
};

export {slider_insta};
