import debounce from 'lodash.debounce';
import Swiper, { Pagination } from 'swiper';

let node = undefined;
let slider = undefined;

const change_slide = slide => {
    try {
        node.querySelector('.swiper-wrapper').dataset.currentSlide = slide.dataset.index;
    } catch (e) {
        // empty;
    }
};

const window_switcher = () => {
    if (document.documentElement.clientWidth < 768) {

        if (!slider) {
            slider = new Swiper(node, {
                modules: [Pagination],
                slidesPerView: 'auto',
                spaceBetween: 24,
                pagination: {
                    el: '.swiper-pagination-howto',
                },
                on: {
                    slideChange: slider => {
                        slider.el.dataset.howtoSlide = slider.activeIndex;
                    }
                }
            });
        }
        return;
    } else if (slider) {
        slider.destroy(true, true);
        slider = null;
    }
};

const slider_howto = () => {
    node = document.querySelector('.swiper-howto');
    if (!node) {
        return;
    }

    window_switcher();
    const resize_listener = debounce(window_switcher, 50);
    window.addEventListener('resize', resize_listener);

    // document.addEventListener('turbo:before-visit', () => {
    //     window.removeEventListener('resize', resize_listener);
    //     if (slider) {
    //         slider.destroy(true, true);
    //         slider = null;
    //     }
    // });

    document.querySelectorAll('.howto-card-change').forEach((node) => {
        node.addEventListener('click', event => {
            event.stopPropagation();
            change_slide(event.currentTarget);
        });
    });
};

export {slider_howto};
