const ACTIVE = 'dd-box-active';

const hide_dd_box = event => {
  if (event.target.classList.contains('dd-button')) {
    return;
  }

  document.querySelectorAll('.dd-box-active').forEach(x => x.classList.remove(ACTIVE));
  document.removeEventListener('click', hide_dd_box);
}

function dd_toggle() {
  const box = this.closest('.dd-box');

  if (!box) {
    return;
  }

  if (box.classList.contains(ACTIVE)) {
    box.classList.remove(ACTIVE);
    document.removeEventListener('click', hide_dd_box);
  } else {
    box.classList.add(ACTIVE);
    document.addEventListener('click', hide_dd_box);
  }
}

const lang_switch = () => {
  const ddbuttons = document.querySelectorAll('.dd-button');

  ddbuttons.forEach(node => {
    node.addEventListener('click', dd_toggle);
  })
};

export {lang_switch};
