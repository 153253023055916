

const sandwich = () => {
    const btn = document.querySelector('.header-btn_menu');
    if (!btn) {
        return;
    }

    btn.addEventListener('click', () => document.body.classList.add('menu-opened'));

    document.addEventListener('click', (event) => {
        if (event.target.dataset && event.target.dataset.invoke === 'hide-nav') {
            document.body.classList.remove('menu-opened');
        }
    });
};

export {sandwich};
