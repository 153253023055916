const two_cols_nav = () => {
    const links = document.querySelectorAll('.two-nav-item-parent');
    links.forEach(node => {
        const holder = node.querySelector('.two-nav-item-parent-holder');
        if (holder) {
            holder.addEventListener('click', function(event) {
                event.preventDefault();
                event.stopPropagation();
                const box = this.closest('.two-nav-item');
                box.classList.toggle('active');
            });
        }

        // node.addEventListener('click', function(event){
        //     const box = this.closest('.two-nav-item');
        //     const children = box.querySelectorAll('.two-nav-sublist li');
        //     if (children.length > 0) {
        //         event.preventDefault();
        //         event.stopPropagation();
        //         box.classList.toggle('active');
        //     }
        // });

        node.addEventListener('mouseenter', function(){
            const current = this.closest('.two-nav-list').querySelector('.hover-active');
            if (current) {
                current.classList.remove('hover-active');
            }
            this.closest('.two-nav-item').classList.add('hover-active');
        });
    });
};

export {two_cols_nav};
