// import debounce from 'lodash.debounce';
import Swiper, { Pagination, Navigation } from 'swiper';

let node = undefined;
let slider = undefined;

const slider_gallery = () => {
  node = document.querySelector('.swiper-image-gallery');

  if (!node) {
    return;
  }

  slider = new Swiper(node, {
    modules: [Pagination, Navigation],
    slidesPerView: 2,
    spaceBetween: 16,
    // pagination: {
    //   el: '.swiper-pagination-gallery',
    //   clickable: true
    // },
    navigation: {
      nextEl: '.swiper-button-next-gallery',
      prevEl: '.swiper-button-prev-gallery',
    },
    breakpoints: {
      // when window width is >= 320px
      768: {
        slidesPerView: 3,
        spaceBetween: 24
      },
    }
  });

};

export {slider_gallery};
