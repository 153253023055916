import debounce from 'lodash.debounce';
import Swiper from 'swiper';

const sliders = [];

const window_switcher = () => {
  if (document.documentElement.clientWidth < 768) {
    if (sliders.length === 0) {
      document.querySelectorAll('.swiper-site').forEach(node => {
        const slider = new Swiper(node, {
          slidesPerView: 'auto',
          spaceBetween: 16,
        });
        sliders.push(slider)
      });
    }
    return;
  }

  while (sliders.length > 0) {
    const slider = sliders.pop();
    slider.destroy(true, true);
  }
};



const base_sliders = () => {
  window_switcher();
  const resize_listener = debounce(window_switcher, 50);
  window.addEventListener('resize', resize_listener);


  document.addEventListener('turbo:before-visit', () => {
    window.removeEventListener('resize', resize_listener);
  });

  // document.querySelectorAll('.swiper-site').forEach(node => {

  // });
  // node = document.querySelector('.swiper-projects');
  // if (!node) {
  //     return;
  // }

  // window_switcher();
  // const resize_listener = debounce(window_switcher, 50);
  // window.addEventListener('resize', resize_listener);

  // document.addEventListener('turbo:before-visit', () => {
  //     window.removeEventListener('resize', resize_listener);
  //     if (slider) {
  //         slider.destroy(true, true);
  //         slider = null;
  //     }
  // });
};


export {base_sliders};
