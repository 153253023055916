
const howto_click = () => {
  document.querySelectorAll('.swiper-howto .swiper-slide').forEach(node => {
    node.addEventListener('click', event => {
      const self = event.currentTarget;
      const parent = self.closest('.swiper-wrapper');
      if (self.dataset.slideIndex === parent.dataset.currentSlide) {
        window.location = self.querySelector('.howto-card-btn').href;
      }
    })
  })
};

export {howto_click}
