// import debounce from 'lodash.debounce';

const tab_switch = () => {
  // let tab = document.querySelector('.prj-nav-tab-btn.active');
  // let box = document.querySelector('.prj-body-c.active');

  // if (!tab || !box) {
  //   return;
  // }

  // console.log(tab);
  // console.log(box);

  document.querySelectorAll('.prj-nav-tab-btn').forEach(node => {
    node.addEventListener('click', function() {
      if (this.classList.contains('active')) {
        return;
      }

      const container = this.closest('.prj-box');
      const tab = container.querySelector('.prj-nav-tab-btn.active');
      const box = container.querySelector('.prj-body-c.active');

      if (tab) {
        tab.classList.remove('active');
      }
      if (box) {
        box.classList.remove('active');
      }

      try {
        this.classList.add('active');
        container.querySelector(`#${this.dataset.target}`).classList.add('active');
      } catch (e) {
        // console.log(e);
      }



    });
  })

};

export {tab_switch};
