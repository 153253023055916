import debounce from 'lodash.debounce';
import Swiper, { Pagination } from 'swiper';

let node = undefined;
let slider = undefined;

const window_switcher = () => {
    if (document.documentElement.clientWidth < 768) {
        if (!slider) {
            slider = new Swiper(node, {
                modules: [Pagination],
                slidesPerView: 'auto',
                spaceBetween: 16,
                pagination: {
                    el: '.swiper-pagination-projects',
                },
            });
        }
        return;
    } else if (slider) {
        slider.destroy(true, true);
        slider = null;
    }
};

const slider_projects = () => {
    node = document.querySelector('.swiper-projects');
    if (!node) {
        return;
    }

    window_switcher();
    const resize_listener = debounce(window_switcher, 50);
    window.addEventListener('resize', resize_listener);

    document.addEventListener('turbo:before-visit', () => {
        window.removeEventListener('resize', resize_listener);
        if (slider) {
            slider.destroy(true, true);
            slider = null;
        }
    });
};

export {slider_projects};
