import queryString from 'query-string';

const project_filter = () => {
    const path = window.location.pathname;
    const api = '/api/project-filter';
    const state = queryString.parse(location.search);

    const directions = document.querySelectorAll('.projects-directions-filter__item p[data-category]');
    const categories = document.querySelectorAll('.project-filter__item a');
    const main_box = document.querySelector('#main-projects-wrapper');

    if (!main_box) {
        return;
    }

    const load_projects = async () => {
        main_box.classList.add('data-load');

        let stringified = queryString.stringify(state);
        const response = await fetch(`${api}?${stringified}`);

        if (response.ok) {
            const code = await response.text();
            main_box.innerHTML = code;
            stringified = stringified === '' ? '' : `?${stringified}`;
            history.replaceState({}, '', `${path}${stringified}`);
        } else {
            console.error(response);
        }

        main_box.classList.remove('data-load');
    };

    directions.forEach(node => {
        node.addEventListener('click', function(){
            state.did = this.dataset.category;
            load_projects();
        });
    });

    categories.forEach(node => {
        node.addEventListener('click', function(){
            state.tid = this.dataset.typeId;
            load_projects();
        });
    });





    // const cat_filters = document.querySelectorAll('.project-filter__item a');

    // const main_box = document.querySelector('#main-projects-wrapper');


    // cat_filters.forEach(node => {
    //     node.addEventListener('ajax:before', () => {
    //         if (main_box) {
    //             main_box.classList.add('data-load');
    //         }
    //     });

    //     node.addEventListener('ajax:success', event => {
    //         const [data, ststus, xhr] = event.detail;
    //         const response = xhr.response;
    //         const box = document.querySelector('#main-projects-wrapper');
    //         if (main_box) {
    //             main_box.innerHTML = response;
    //         }
    //     });

    //     node.addEventListener('ajax:complete', () => {
    //         if (main_box) {
    //             main_box.classList.remove('data-load');
    //         }
    //     });
    // });
}

export {project_filter};
