import debounce from 'lodash.debounce';
import Swiper, { Pagination } from 'swiper';

let node = undefined;
let slider = undefined;

const window_switcher = () => {
    if (document.documentElement.clientWidth < 768) {
        slider = new Swiper(node, {
            modules: [Pagination],
            slidesPerView: 'auto',
            spaceBetween: 24,
            pagination: {
                el: '.swiper-pagination-top-projects',
                clickable: true
            },
        });
    } else {
        slider = new Swiper(node, {
            modules: [Pagination],
            slidesPerView: '1',
            spaceBetween: 24,
            pagination: {
                el: '.swiper-pagination-top-projects',
                clickable: true
            },
        });
    }
    slider.update();
};

const slider_top_projects = () => {
    node = document.querySelector('.swiper-top-projects');
    if (!node) {
        return;
    }

    window_switcher();
    const resize_listener = debounce(window_switcher, 50);
    window.addEventListener('resize', resize_listener);

    document.addEventListener('turbo:before-visit', () => {
        window.removeEventListener('resize', resize_listener);
        if (slider) {
            slider.destroy(true, true);
            slider = null;
        }
    });
};

export {slider_top_projects};
