
function setPreviewImage(input) {
  const fileList = input.files;
  if (fileList.length < 1) {
    return;
  }

  const file = fileList[0];

  if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
    console.warn(`ProjectImageWidget: unsupported file format "${file.type}"`);
    input.value = '';
    return;
  }

  const container = input.closest('.project-file-box').querySelector('.project-file-preview');

  const reader = new FileReader();
  reader.addEventListener('load', (event) => {
    container.innerHTML = `<img src="${event.target.result}">`;

    const listItem = container.closest('.project-file-item');
    const nextElement = listItem.nextElementSibling;
    if (nextElement) {
      nextElement.querySelector('input[type="file"]').disabled = false;
    }

  });
  reader.readAsDataURL(file);
}


const image_widget = () => {
  const inputsList = document.querySelectorAll('.project-file-input');

  if (inputsList.length === 0) {
    return;
  }

  inputsList.forEach(input => {
    input.addEventListener('change', function(){
      setPreviewImage(this);
    });

    const dropArea = input.closest('.project-file-box');
    if (dropArea) {
      dropArea.addEventListener('dragover', (event) => {
        if (input && !input.disabled) {
          event.stopPropagation();
          event.preventDefault();
          event.dataTransfer.dropEffect = 'copy';
          dropArea.classList.add('dnd-highlight');
        }
      });

      dropArea.addEventListener('drop', (event) => {
        if (input && !input.disabled) {
          event.stopPropagation();
          event.preventDefault();
          const files = event.dataTransfer.files;
          input.files = files;
          const errBlock = input.closest('.request-form-line_error');
          if (errBlock) {
            errBlock.classList.remove('request-form-line_error');
          }

          setPreviewImage(input);
          dropArea.classList.remove('dnd-highlight');
        }
      });

      dropArea.addEventListener('dragleave', (event) => {
        if (input && !input.disabled) {
          dropArea.classList.remove('dnd-highlight');
        }
      });
    }
  });

  document.body.addEventListener('drop', (event) => {
    event.stopPropagation();
    event.preventDefault();
  });
};

export {
  image_widget
};
