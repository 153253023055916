const form_helper = () => {

  const setValue = node => {
    try {
      node.closest('form').querySelector('#user_value').value = node.value;
    } catch(e) {
      // empty
    }
  };

  document.querySelectorAll('form.money-form').forEach(form => {
    form.querySelectorAll('input[name="sum"]').forEach(input => {
      if (input.checked) {
        setValue(input);
      }
      input.addEventListener('click', function(){
        setValue(this);
      });
    });
  });

};

export {form_helper};
