
const input_file = () => {

  document.querySelectorAll('.wdgt-form-files input[type="file"]').forEach(element => {
    element.addEventListener('change', function() {
      try {
        this.closest('.wdgt-form-files').querySelector('.wdgt-file-list').innerHTML = Array.from(this.files).map(x => `<li>${x.name}</li>`).join('');
      } catch (e) {
        // empty
      }
    })
  });
}

export {
  input_file
};

