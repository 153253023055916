const URL = '/children_paginate';

function getCurrentParams(id) {
  const params = new URLSearchParams(window.location.search);
  id !== '0' ? params.set('status', id) : params.delete('status');
  return params.toString();
}

function getData(id) {
  const params = getCurrentParams(id) ;

  return fetch(`${URL}?${params}`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  }).then(response => {
    const type = response.headers.get('content-type').split(';')[0];
    if (type === 'text/javascript') {
      return response.text();
    }
    throw new Error('Unsupported response type');
  });
}

function getFilteredList() {
  const self = this;
  const id = self.value;
  self.blur();
  document.body.classList.add('fetch-gifts-data');
  getData(id).then((response) => {
    eval(response);
  }).then(() => {
    const params = getCurrentParams(id);
    const newUrl = params === '' ? window.location.pathname : `${window.location.pathname}?${params}`
    window.history.replaceState({}, '', newUrl);
  }).finally(() => {
    document.body.classList.remove('fetch-gifts-data');
  });
}

function filterThousands() {
  const filter = document.querySelector('#filter-status');
  if (!filter) return;

  filter.addEventListener('change', getFilteredList);
}

export {
  filterThousands
};
