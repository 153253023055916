
const validate = form => {
  const inputs = form.querySelectorAll('input[data-required="true"], textarea[data-required="true"]');
  let result = true;
  let re;
  let local;

  inputs.forEach(input => {
    if (input.name == 'date_end') {
      if (form.querySelector('input[name="no_date_end"]').checked)   {
        local = true
      } else {
        local = input.value !== '';
      }
    }else if (input.type === 'checkbox') {
      local = input.checked;
    } else if (input.dataset.regexp && input.dataset.regexp !== '') {
      re = new RegExp(input.dataset.regexp, 'i');
      local = re.test(input.value);
    } else {
      local = input.value !== '';
    }
    if (!local) {
      input.closest('.request-form-line').classList.add('request-form-line_error');
    }
    result = result && local;
  });

  const selects = form.querySelectorAll('select[data-required="true"]');
  selects.forEach(select => {
    if (select.value === '') {
      result = result && false;
      const contanier = select.closest('.theme-select');
      if (contanier) {
        contanier.classList.add('request-form-line_error');
      }
    }
  });

  const files = form.querySelectorAll('input[data-file-required="true"]');
  files.forEach(input => {
    const fileListPresent = input.files.length > 0;
    result = result && fileListPresent;
    if (!fileListPresent) {
      const container = input.closest('.wdgt-form-files');
      if (container) {
        container.classList.add('request-form-line_error');
      }
    }
  })

  const images = form.querySelectorAll('input[data-image-required="true"]');
  images.forEach(input => {
    const fileListPresent = input.files.length > 0;
    result = result && fileListPresent;
    if (!fileListPresent) {
      const container = input.closest('.project-file-box');
      if (container) {
        container.classList.add('request-form-line_error');
      }
    }
  })

  if (!result) {
    const firstError = form.querySelector('.request-form-line_error');
    if (firstError) {
      firstError.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return result;
}


const check_form = () => {
  document.querySelectorAll('[data-check-form]').forEach(form => {
    form.addEventListener('ajax:before', function(event) {
        if (!validate(event.currentTarget)) {
            event.preventDefault();
        }
    });
  });

  document.querySelectorAll('[data-check-form] input[data-required="true"], [data-check-form] textarea[data-required="true"]').forEach(input => {
    input.addEventListener('focus', function(){
      try {
        this.closest('.request-form-line').classList.remove('request-form-line_error');
      } catch (e) {
        // emtpy
      }
    });
  });

  document.querySelectorAll('input[type="checkbox"][data-required="true"]').forEach(input => {
    input.addEventListener('click', function(){
      try {
        this.closest('.request-form-line').classList.remove('request-form-line_error');
      } catch (e) {
        // emtpy
      }
    });
  });

  document.querySelectorAll('select[data-required="true"]').forEach(select => {
    select.addEventListener('change', function() {
      const container = this.closest('.theme-select');
      if (container) {
        container.classList.remove('request-form-line_error');
      }
    });
  });

  document.querySelectorAll('input[data-file-required="true"]').forEach(input => {
    input.addEventListener('change', function() {
      const container = this.closest('.wdgt-form-files');
      if (container) {
        container.classList.remove('request-form-line_error');
      }
    });
  });

  document.querySelectorAll('input[data-image-required="true"]').forEach(input => {
    input.addEventListener('change', function() {
      const container = this.closest('.project-file-box');
      if (container) {
        container.classList.remove('request-form-line_error');
      }
    });
  });


  document.querySelectorAll('input[data-type="date"]').forEach(input => {
    flatpickr(input, {
      'locale': document.documentElement.lang,
      dateFormat: 'd.m.Y',
    });
  })
}

const choices = {};

const load_city_request = async (region_id, elem) => {
  const select = elem.closest('.address-select-box').querySelector('.city_select');
  const choise = choices[select.name];

  const response = await fetch(`/api/form_cities?region_id=${region_id}`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    }
  });

  const data = await response.text();

  choise.destroy();
  select.disabled = false;
  select.innerHTML = data;

  const parentBlock = select.closest('.theme-select');
  if (parentBlock) {
    parentBlock.classList.remove('request-form-line_error');
  }

  choices[select.name] = new Choices(select, {
    searchEnabled: select.dataset.searchEnabled == '1',
    itemSelectText: ''
  });
};


const request_forms = () => {
  document.querySelectorAll('.theme-select-choice select').forEach(node => {
    choices[node.name] = new Choices(node, {
      searchEnabled: node.dataset.searchEnabled == '1',
      itemSelectText: ''
    });
  });

  document.querySelectorAll('.region_change').forEach(elem => {
    elem.addEventListener('change', function() {
      load_city_request(this.value, elem);
    });
  });
}


export {
  request_forms,
  check_form
};
