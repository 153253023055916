// import debounce from 'lodash.debounce';
import Swiper, { Pagination, Navigation } from 'swiper';

let node = undefined;
let slider = undefined;

const slider_donors = () => {
  node = document.querySelector('.swiper-partners-gallery');

  if (!node) {
    return;
  }

  slider = new Swiper(node, {
    modules: [Pagination, Navigation],
    slidesPerView: "auto",
    spaceBetween: 16,
    slidesOffsetAfter: 16,
    loop: false,
    pagination: {
      el: '.swiper-pagination-partners',
      clickable: true,
      dynamicBullets: true,
    },
    navigation: {
      nextEl: '.swiper-button-next-partners',
      prevEl: '.swiper-button-prev-partners',
    },
    breakpoints: {
      // when window width is >= 320px
      768: {
        slidesPerView: 2,
        spaceBetween: 16,
        slidesOffsetAfter: 0
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 24
      },
    }
  });

};

export {slider_donors};
