const faq = () => {

    const items = document.querySelectorAll('.faq-question span');
    items.forEach(node => node.addEventListener('click', function(){
        try {
            this.closest('.faq-item').classList.toggle('faq-item_active');
        } catch(e) {
            // emty;
        }
    }));
};

export {faq};
