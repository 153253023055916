

const donation_form_switch = () => {
  const hash = window.location.hash.replace('#', '');
  const list = Array.from(document.querySelectorAll('.help-money-toggle-head__item'));

  if (!hash || hash === '' || list.length === 0) {
    return;
  }

  let target;

  switch (hash) {
    case 'donate_bank':
    case 'money':
      target = list.find(x => x.dataset.blockType === 'donate_bank');
      break;
    case 'donate_card':
      target = list.find(x => x.dataset.blockType === 'donate_card');
      break;
    case 'donate_sms':
      target = list.find(x => x.dataset.blockType === 'donate_sms');
      break;
    case 'donate_other_countries':
      target = list.find(x => x.dataset.blockType === 'donate_other_countries');
      break;
  }

  try {
    const container = document.querySelector('.help-money-toggle');
    container.querySelectorAll('._active-page').forEach(x => x.classList.remove('_active-page'));
    target.classList.add('_active-page');
    const data = document.querySelector(target.dataset.togglePage);
    data.classList.add('_active-page');
    data.removeAttribute('style');
  } catch (error) {
    console.error(error);
  }

  list.forEach(node => {
    node.addEventListener('click', function(){
      window.location.hash = `#${this.dataset.blockType}`;
    });
  })
};

export {
  donation_form_switch
};
