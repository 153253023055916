
const form_user_create_project = () => {

  (() => {
    const user_project_select = document.querySelector('select[data-post-action="preview_project"]');

    if (!user_project_select) {
      return;
    }

    function writePreview(data) {
      let picture = data.image.map(pic => `<source srcset="${pic.img}" type="image/${pic.content}">`).join('');

      const fallback = data.image.pop();
      picture += `<img src="${fallback.img}" alt="${data.name}">`;

      return `<div class="cp-project-card">
        <a href="${data.url}" class="cp-project-img">
          <picture>${picture}</picture>
        </a>
        <a href="${data.url}" targer="_blank" class="cp-project-name">${data.name}</a>
      </div>`;
    }

    async function setProjectPreview() {
      const id = this.value;
      const url = this.dataset.infoUrl;
      const lang = document.documentElement.lang;
      const mountpoint = document.querySelector('.cp-project-placeholder');

      if (id === '' || url === '' || !mountpoint) {
        return;
      }

      try {
        const response = await fetch(`${url}?id=${id}`);
        if (response.ok) {
          const project = await response.json();
          mountpoint.innerHTML = writePreview(project);
        }
        } catch (error) {
        console.error(error);
        return;
      }
    }

    user_project_select.addEventListener('change', setProjectPreview);
    setProjectPreview.call(user_project_select);
  })();

};

export {
  form_user_create_project
}

