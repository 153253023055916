function copyStringToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

const copy_link = () => {
    const btn = document.querySelector('.do_copy');
    if (!btn) {
        return;
    }

    btn.addEventListener('click', () => {
        const self = event.currentTarget;
        copyStringToClipboard(self.dataset.href);
        self.classList.add('do_copy_active');
        setTimeout(() => { self.classList.remove('do_copy_active'); }, 2000);
    });

};

export { copy_link };