const copybox = () => {
  if (navigator.clipboard) {
    document.querySelectorAll('.copybox').forEach(node => {
      node.addEventListener('click', function(){
        navigator.clipboard.writeText(this.innerHTML);
      });
    });
  }
}

export {
  copybox
};
